import React from 'react';
import _ from 'lodash';
import useSWR from 'swr';
import { swrFetcher } from 'utils/api';

const { createContext, useContext, useState, useEffect } = React;

export const AppLaunchContext = createContext({});
AppLaunchContext.displayName = 'AppLaunch';

export const AppLaunchProvider = (props) => {
  const { children } = props;

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    '/api/v2/appLaunch',
    (url) => swrFetcher(url)
  );

  const categoryTree = _.get(data, 'category_tree', []) || {};
  const notifications = _.get(data, 'notifications', []) || [];
  const activityTags = _.get(data, 'activity_tags', []) || [];
  const campaigns = _.get(data, 'campaigns', []) || [];
  const factorTypes = _.get(data, 'factor_types', []) || [];
  const stores = _.get(data, 'stores', []) || [];
  const productOrigins = _.get(data, 'product_origins', []) || [];
  const ingredientSafeties = _.get(data, 'ingredient_safeties', []) || [];
  const contentCounts = _.get(data, 'content_counts', null) || {
    product_count: 0,
    review_count: 0,
    category_count: 0,
  };

  const firstCategoryMap = _.reduce(
    categoryTree,
    (acc, firstCategory) => {
      acc[firstCategory.id] = firstCategory;
      return acc;
    },
    {}
  );

  const secondCategoryMap = _.reduce(
    categoryTree,
    (acc, firstCategory) => {
      const secondCategories = firstCategory.second_categories;
      _.forEach(secondCategories, (secondCategory) => {
        acc[secondCategory.id] = secondCategory;
      });
      return acc;
    },
    {}
  );

  const activityTagMap = _.reduce(
    activityTags,
    (acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    },
    {}
  );

  const campaignMap = _.reduce(
    campaigns,
    (acc, campaign) => {
      acc[campaign.id] = campaign;
      return acc;
    },
    {}
  );

  const factors = _.reduce(
    factorTypes,
    (acc, factorType) => {
      return acc.concat(factorType.factors);
    },
    []
  );

  const factorMap = _.reduce(
    factors,
    (acc, factorType) => {
      acc[factorType.id] = factorType;
      return acc;
    },
    {}
  );

  const storeMap = _.reduce(
    stores,
    (acc, store) => {
      acc[store.id] = store;
      return acc;
    },
    {}
  );

  const productOriginMap = _.reduce(
    productOrigins,
    (acc, origin) => {
      acc[origin.id] = origin;
      return acc;
    },
    {}
  );

  const ingredientSafetyMap = _.keyBy(ingredientSafeties, 'id');

  return (
    <AppLaunchContext.Provider
      value={{
        isLoading,
        categoryTree,
        firstCategoryMap,
        secondCategoryMap,
        activityTagMap,
        factorMap,
        campaignMap,
        storeMap,
        productOriginMap,
        notifications,
        ingredientSafeties,
        ingredientSafetyMap,
        contentCounts,
      }}
    >
      {children}
    </AppLaunchContext.Provider>
  );
};

export const useAppLaunch = () => {
  return useContext(AppLaunchContext);
};
