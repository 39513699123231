import React from 'react';
import { Noto_Sans, Noto_Sans_TC, Rubik, Bodoni_Moda } from 'next/font/google';
import { DefaultSeo } from 'next-seo';
// import Header from 'components/Header';

// import api from 'utils/api';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import Modal from 'react-modal';
import { GlobalConfigProvider } from 'contexts/GlobalConfig';
import { FirebaseAuthProvider } from 'contexts/FirebaseAuth';
import { AppLaunchProvider } from 'contexts/AppLaunch';
import { UserAuthProvider } from 'contexts/UserAuth';
import SEO from 'utils/next-seo.config';

import RatingModal from 'components/RatingModal';

export const notoSans = Noto_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans',
});

export const notoSansTC = Noto_Sans_TC({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans-tc',
});

export const rubik = Rubik({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-rubik',
});

export const bodoniModa = Bodoni_Moda({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-bodoni-moda',
});

import 'styles/globals.css';
import 'styles/menubar.css';
import 'styles/mobileTabBar.css';
import 'styles/footer.css';
import 'styles/index.css';
import 'styles/search.css';
import 'styles/ranking.css';
import 'styles/product.css';
import 'styles/rewards.css';
import 'styles/react-responsive-carousel-override.css';
import 'styles/register/registerPopup.css';
import 'styles/loginModal.css';
import 'styles/commentPopup.css';
import 'styles/override.css';

import { GoogleAnalyticsTracking } from 'utils/GoogleAnalyticsTracking';

const { useEffect } = React;

export default function App({ Component, pageProps }) {
  const { asPath } = useRouter();
  useEffect(() => {
    Modal.setAppElement('#app-wrapper');
  });
  useEffect(() => {
    document.querySelector('#app-wrapper').scrollTo(0, 0);
  }, [asPath]);
  return (
    <GlobalConfigProvider
      fonts={{
        rubik,
      }}
    >
      <DefaultSeo {...SEO} />
      <style jsx global>{``}</style>
      <div
        id="app-wrapper"
        className={`h-full w-full overflow-y-auto ${notoSans.variable} ${notoSansTC.variable} ${rubik.variable} ${bodoniModa.variable}`}
      >
        <div className="relative font-sans">
          <SWRConfig
            value={{
              revalidateOnFocus: true,
            }}
          >
            <GoogleAnalyticsTracking />
            <AppLaunchProvider>
              <FirebaseAuthProvider>
                <UserAuthProvider>
                  <Component {...pageProps} />
                  <RatingModal />
                </UserAuthProvider>
              </FirebaseAuthProvider>
            </AppLaunchProvider>
          </SWRConfig>
        </div>
      </div>
    </GlobalConfigProvider>
  );
}
